
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const auth = namespace("auth");

@Component
export default class AppHeader extends Vue {
  get headerHeight(): string {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return "60px";
      case "sm":
        return "60px";
      case "md":
        return "68px";
      case "lg":
        return "68px";
      case "xl":
        return "68px";
      default:
        return "68px";
    }
  }

  @auth.Action
  logout!: () => Promise<any>;

  requestLogout(): void {
    this.logout().then(() => {
      this.$router.push("/login");
    });
  }
}
