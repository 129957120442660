export default class Radiologist {
  avatarUpload: any;
  avatarUrl: string | null;
  createdAt: string | null;
  firstName: string | null;
  id: string | null;
  lastName: string | null;
  prefix: string | null;
  suffix: string | null;
  middleName: string | null;
  slug: string | null;
  primarySpecialty: string | null;
  medicalSchool: string | null;
  residency: string | null;
  internship: string | null;
  fellowship: string | null;
  boardCertification: string | null;
  certification: string | null;
  memberships: string | null;
  professionalOrganizations: string | null;
  stricFeatured = false;
  boutiqueFeatured = false;

  constructor() {
    this.avatarUpload = null;
    this.avatarUrl = null;
    this.createdAt = null;
    this.firstName = null;
    this.id = null;
    this.lastName = null;
    this.prefix = null;
    this.suffix = null;
    this.middleName = null;
    this.slug = null;
    this.primarySpecialty = null;
    this.medicalSchool = null;
    this.residency = null;
    this.internship = null;
    this.fellowship = null;
    this.boardCertification = null;
    this.certification = null;
    this.memberships = null;
    this.professionalOrganizations = null;
    this.stricFeatured = false;
    this.boutiqueFeatured = false;
  }

  static fromJSON(json: any): Radiologist {
    const radiologist = new Radiologist();

    radiologist.avatarUrl = json.avatar_url;
    radiologist.createdAt = json.created_at;
    radiologist.firstName = json.first_name;
    radiologist.id = json.id;
    radiologist.lastName = json.last_name;

    radiologist.prefix = json.prefix;
    radiologist.suffix = json.suffix;
    radiologist.middleName = json.middle_name;
    radiologist.slug = json.slug;
    radiologist.primarySpecialty = json.primary_specialty;
    radiologist.medicalSchool = json.medical_school;
    radiologist.residency = json.residency;
    radiologist.internship = json.internship;
    radiologist.fellowship = json.fellowship;
    radiologist.boardCertification = json.board_certification;
    radiologist.certification = json.certification;
    radiologist.memberships = json.memberships;
    radiologist.professionalOrganizations = json.professional_organizations;
    radiologist.stricFeatured = json.stric_featured;
    radiologist.boutiqueFeatured = json.boutique_featured;

    return radiologist;
  }

  toJSON(): any {
    return {
      first_name: this.firstName,
      last_name: this.lastName,
      prefix: this.prefix,
      suffix: this.suffix,
      middle_name: this.middleName,
      slug: this.slug,
      primary_specialty: this.primarySpecialty,
      medical_school: this.medicalSchool,
      residency: this.residency,
      internship: this.internship,
      fellowship: this.fellowship,
      board_certification: this.boardCertification,
      certification: this.certification,
      memberships: this.memberships,
      professional_organizations: this.professionalOrganizations,
      stric_featured: this.stricFeatured,
      boutique_featured: this.boutiqueFeatured,
    };
  }

  toFormData(): FormData {
    const formData = new FormData();

    const vals: Record<string, any> = {
      first_name: this.firstName,
      last_name: this.lastName,
      prefix: this.prefix,
      suffix: this.suffix,
      middle_name: this.middleName,
      slug: this.slug,
      primary_specialty: this.primarySpecialty,
      medical_school: this.medicalSchool,
      residency: this.residency,
      internship: this.internship,
      fellowship: this.fellowship,
      board_certification: this.boardCertification,
      certification: this.certification,
      memberships: this.memberships,
      professional_organizations: this.professionalOrganizations,
      stric_featured: this.stricFeatured,
      boutique_featured: this.boutiqueFeatured,
    };

    for (const k in vals) {
      const val = vals[k];
      if (val) {
        formData.append(k, val);
      }
    }

    if (this.avatarUpload) {
      formData.append("avatar", this.avatarUpload);
    }

    return formData;
  }

  fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
