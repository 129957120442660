import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";

import "./plugins/vue-cookies";
import vuetify from "./plugins/vuetify";

import Notifications from "vue-notification";
import VueClipboard from "vue-clipboard2";
import { setInteractionMode } from "vee-validate";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { version } from "../package.json";

Vue.use(Notifications);
setInteractionMode("eager");

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

if (
  process.env.NODE_ENV === "production" ||
  process.env.NODE_ENV === "staging"
) {
  Sentry.init({
    Vue,
    dsn: "https://d3caa5e538cb4ea2af753a0e9552ff64@o235257.ingest.sentry.io/6440226",
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    release: `stric-console-web@${version}`,
    tracesSampleRate: 1.0,
    tracingOptions: {
      trackComponents: true,
    },
  });
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
