
import { Component, Vue } from "vue-property-decorator";
import { version } from "../../package.json";

@Component
export default class AppFooter extends Vue {
  get version(): string {
    return version;
  }
}
