
import Vue from "vue";

export default Vue.extend({
  name: "Login",

  data: () => ({
    email: null,
    password: null,
    error: null,
    isLoading: false,
  }),
});
