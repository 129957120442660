
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import MOTD from "../../models/motd";
import NullableField from "../NullableField.vue";

@Component({
  components: {
    NullableField,
  },
})
export default class MOTDsTable extends Vue {
  @Prop({ required: true }) readonly motds!: MOTD[];
  @Prop({ default: false }) readonly singleSelect!: boolean;

  headers: Record<string, any>[] = [
    {
      text: "Text",
      value: "text",
    },
    {
      text: "Publicly Visible?",
      value: "visible",
    },
    {
      text: "Redirect URL",
      value: "url",
    },
    {
      text: "",
      value: "edit",
      sortable: false,
    },
  ];

  isLoading = false;

  selected: string[] = [];

  options: any = {
    sortDesc: [false],
    page: 1,
    itemsPerPage: 20,
    sortBy: ["lastName"],
  };

  itemsPerPageOptions = [20, 50];
  totalItems = 0;

  @Watch("selected")
  updateSelected(newVal: MOTD[], _oldVal: MOTD[]) {
    const motdIds = newVal.map((motd) => motd.id);
    this.$emit("update-selected", motdIds);
  }

  get footerProps(): any {
    return { "items-per-page-options": this.itemsPerPageOptions };
  }

  offset(): number {
    const { page, itemsPerPage } = this.options;
    return (page - 1) * itemsPerPage;
  }

  sortParam(key: string): string {
    const keys: any = {
      createdAt: "inserted_at",
      id: "id",
      name: "name",
      companyName: "company_name",
      firstName: "first_name",
      lastName: "last_name",
      jobTitle: "job_title",
      stage: "stage",
      frMrr: "fr_mrr",
      frLicenseCount: "fr_license_count",
      email: "email",
      numberOfAgents: "num_agents",
    };

    return keys[key];
  }

  edit(motd: MOTD) {
    this.$emit("edit", motd);
  }
}
