
import Vue from "vue";
import AppFooter from "./components/AppFooter.vue";
import AppHeader from "./components/AppHeader.vue";

export default Vue.extend({
  name: "App",

  components: {
    AppFooter,
    AppHeader,
  },

  data: () => ({
    showRoutes: ["Home", "Radiologists", "MOTDs", "Users"],
  }),
});
