import axios from "axios";
import Radiologist from "../models/radiologist";
import Response from "../models/response";
import Util from "./util";

export default {
  all(params: any): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/radiologists";

    return axios
      .get(url, { params: params, headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  create(radiologist: Radiologist): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/radiologists";
    const params = radiologist.toFormData();

    return axios
      .post(url, params, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  delete(radiologistId: string): Promise<Response> {
    const url = `${process.env.VUE_APP_API_URL}/api/v1/radiologists/${radiologistId}`;

    return axios
      .delete(url, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  update(radiologistId: string, radiologist: Radiologist): Promise<Response> {
    const url = `${process.env.VUE_APP_API_URL}/api/v1/radiologists/${radiologistId}`;
    const params = radiologist.toFormData();

    return axios
      .put(url, params, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },
};
