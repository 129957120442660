import RadiologistApi from "@/api/radiologist";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
import { State as RootState } from "../state";
import { RadiologistState } from "./radiologistState";
import Radiologist from "../../models/radiologist";
import Response from "../../models/response";

type RadiologistContext = ActionContext<RadiologistState, RootState>;

const state: RadiologistState = {
  isCreating: false,
  isLoading: false,
  isUpdating: false,
  radiologists: [],
};

const getters: GetterTree<RadiologistState, RootState> = {
  getRadiologists(state: RadiologistState): Radiologist[] {
    return state.radiologists;
  },

  isCreating(state: RadiologistState): boolean {
    return state.isCreating;
  },

  isLoading(state: RadiologistState): boolean {
    return state.isLoading;
  },

  isUpdating(state: RadiologistState): boolean {
    return state.isUpdating;
  },
};

const actions: ActionTree<RadiologistState, RootState> = {
  create(context: RadiologistContext, { radiologist }): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("createRequest");

      RadiologistApi.create(radiologist)
        .then((resp: Response) => {
          const data: Radiologist = Radiologist.fromJSON(resp.data);
          context.commit("createSuccess", { radiologist: data });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("createFailure");
          reject(resp);
        });
    });
  },

  delete(
    context: RadiologistContext,
    radiologistId: string
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("deleteRequest");

      RadiologistApi.delete(radiologistId)
        .then((resp: Response) => {
          context.commit("deleteSuccess", { radiologistId: radiologistId });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("deleteFailure");
          reject(resp);
        });
    });
  },

  fetchAll(context: RadiologistContext): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("fetchAllRequest");

      RadiologistApi.all({})
        .then((resp: Response) => {
          const data = resp.data.radiologists.map((d: any) =>
            Radiologist.fromJSON(d)
          );
          context.commit("fetchAllSuccess", { radiologists: data });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("fetchAllFailure");
          reject(resp);
        });
    });
  },

  update(
    context: RadiologistContext,
    { radiologistId, params }
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("updateRequest");

      RadiologistApi.update(radiologistId, params)
        .then((resp: Response) => {
          const data: Radiologist = Radiologist.fromJSON(resp.data);
          context.commit("updateSuccess", { radiologist: data });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("updateFailure");
          reject(resp);
        });
    });
  },
};

const mutations: MutationTree<RadiologistState> = {
  createRequest(state: RadiologistState) {
    state.isCreating = true;
  },

  createSuccess(state: RadiologistState, { radiologist }) {
    state.radiologists.push(radiologist);
    state.isCreating = false;
  },

  createFailure(state: RadiologistState) {
    state.isCreating = false;
  },

  fetchAllRequest(state: RadiologistState) {
    state.isLoading = true;
  },

  fetchAllSuccess(state: RadiologistState, { radiologists }) {
    state.radiologists = radiologists;
    state.isLoading = false;
  },

  fetchAllFailure(state: RadiologistState) {
    state.isLoading = false;
  },

  deleteRequest(_state: RadiologistState) {
    // Nothing yet.
  },

  deleteSuccess(state: RadiologistState, { radiologistId }) {
    state.radiologists = state.radiologists.filter(
      (x) => x.id !== radiologistId
    );
  },

  deleteFailure(_state: RadiologistState) {
    // Nothing yet.
  },

  updateRequest(state: RadiologistState) {
    state.isUpdating = true;
  },

  updateSuccess(state: RadiologistState, { radiologist }) {
    const filtered = state.radiologists.filter((x) => x.id !== radiologist.id);
    filtered.push(radiologist);
    state.radiologists = filtered;
    state.isUpdating = false;
  },

  updateFailure(state: RadiologistState) {
    state.isUpdating = false;
  },
};

export const radiologist: Module<RadiologistState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
