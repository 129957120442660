
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Radiologist from "@/models/radiologist";
import ChangeAvatar from "../ChangeAvatar.vue";
import ErrorBox from "../ErrorBox.vue";

import { extend, ValidationObserver, ValidationProvider } from "vee-validate";

import { messages } from "vee-validate/dist/locale/en.json";
import { required, email, max } from "vee-validate/dist/rules";

extend("required", { ...required, message: messages["required"] });
extend("email", { ...email, message: messages["email"] });
extend("max", { ...max, message: messages["max"] });

@Component({
  components: {
    ChangeAvatar,
    ErrorBox,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class RadiologistDialog extends Vue {
  @Prop({ required: true }) readonly value!: boolean;
  @Prop({ default: "Create a radiologist" }) readonly title!: string;
  @Prop({ default: "Create" }) readonly actionTitle!: string;
  @Prop({ default: false }) readonly isLoading!: boolean;

  errorMessage: string | null = null;

  isOpen = false;

  tempRadiologist: Radiologist = new Radiologist();
  valid = false;

  setRadiologist(radiologist: Radiologist) {
    this.tempRadiologist = radiologist;
  }

  @Watch("value")
  onValueChanged(val: boolean, _oldVal: boolean) {
    if (this.isOpen === val) {
      return;
    }
    this.isOpen = val;
  }

  @Watch("isOpen")
  onIsOpenChanged(_val: boolean, _oldVal: boolean) {
    if (!this.isOpen) {
      this.close();
    }
  }

  close() {
    this.$emit("close");
  }

  changeAvatar(upload: any) {
    this.tempRadiologist.avatarUpload = upload;
  }

  onSubmit() {
    if (this.isLoading) {
      return;
    }

    if (this.tempRadiologist.avatarUpload == null) {
      delete this.tempRadiologist.avatarUpload;
    }

    this.$emit("save", this.tempRadiologist);
  }

  reset() {
    this.tempRadiologist = new Radiologist();
    this.errorMessage = null;
    const changeAvatar: any = this.$refs.changeAvatar;
    changeAvatar.reset();
  }
}
