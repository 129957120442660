export default class User {
  email: string | null;
  firstName: string | null;
  id: string | null;
  lastName: string | null;

  constructor() {
    this.email = null;
    this.firstName = null;
    this.id = null;
    this.lastName = null;
  }

  static fromJSON(json: Record<string, string>): User {
    const user = new User();

    user.email = json.email;
    user.firstName = json.first_name;
    user.id = json.id;
    user.lastName = json.last_name;

    return user;
  }

  static fromLocalStorageJSON(json: Record<string, string>): User {
    const user = new User();

    user.email = json.email;
    user.firstName = json.firstName;
    user.id = json.id;
    user.lastName = json.lastName;

    return user;
  }

  fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
