import Vue from "vue";
import Router from "vue-router";
import store from "../store";

import ForgotPassword from "../views/ForgotPassword.vue";
import Login from "../views/Login.vue";
import MOTDs from "../views/MOTDs.vue";
import NotFound from "../views/NotFound.vue";
import Radiologists from "../views/Radiologists.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Users from "../views/Users.vue";

Vue.use(Router);

const hasToken = () => {
  return store.getters["auth/isAuthenticated"];
};

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  if (!hasToken()) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to: any, from: any, next: any) => {
  if (hasToken()) {
    next();
    return;
  }

  const redirectTo = window.location.pathname;
  if (redirectTo === "/") {
    next({ name: "Login" });
  } else {
    next({ name: "Login", query: { from: redirectTo } });
  }
};

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Radiologists,
      name: "Home",
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/radiologists",
      component: Radiologists,
      name: "Radiologists",
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/announcements",
      component: MOTDs,
      name: "MOTDs",
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/users",
      component: Users,
      name: "Users",
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/login",
      component: Login,
      name: "Login",
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/forgot-password",
      component: ForgotPassword,
      name: "ForgotPassword",
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/reset-password",
      component: ResetPassword,
      name: "ResetPassword",
      beforeEnter: ifNotAuthenticated,
      props: true,
    },
    {
      path: "*",
      component: NotFound,
      name: "NotFound",
    },
  ],
});
