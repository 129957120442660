import API from "@/api";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
import { State as RootState } from "../state";
import { AuthState } from "./authState";
import Response from "../../models/response";
import Vue from "vue";
import User from "../../models/user";

type AuthContext = ActionContext<AuthState, RootState>;

const state: AuthState = {
  isLoading: false,
  loginError: null,
  profile: User.fromLocalStorageJSON(
    JSON.parse(localStorage.getItem("profile") || "{}")
  ),
  token: localStorage.getItem("token"),
};

const getters: GetterTree<AuthState, RootState> = {
  getToken(state: AuthState): string | null {
    return state.token;
  },

  getProfile(state: AuthState): User | null {
    return state.profile;
  },

  getLoginError(state: AuthState): string | null {
    return state.loginError;
  },

  isAuthenticated(state: AuthState): boolean {
    return state.token != null;
  },

  isLoading(state: AuthState): boolean {
    return state.isLoading;
  },
};

const actions: ActionTree<AuthState, RootState> = {
  fetchProfile(context: AuthContext): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("fetchProfileRequest");

      API.fetchProfile()
        .then((resp: Response) => {
          const profile: User = User.fromJSON(resp.data);
          context.commit("fetchProfileSuccess", { profile: profile });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("fetchProfileFailure");
          reject(resp);
        });
    });
  },

  login(context: AuthContext, { email, password }): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("loginRequest");

      API.login(email, password)
        .then((resp: Response) => {
          const token: string = resp.data.token;
          context.commit("loginSuccess", { token: token });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("loginFailure", { error: resp.error.message });
          reject(resp);
        });
    });
  },

  logout(context: AuthContext): Promise<void> {
    return new Promise((resolve, _reject) => {
      context.commit("logout");
      localStorage.clear();
      resolve();
    });
  },
};

const mutations: MutationTree<AuthState> = {
  fetchProfileRequest(_state: AuthState) {
    // Nothing yet.
  },

  fetchProfileSuccess(state: AuthState, { profile }) {
    localStorage.setItem("profile", JSON.stringify(profile));
    state.profile = profile;
  },

  fetchProfileFailure(_state: AuthState) {
    // Nothing yet.
  },

  loginRequest(state: AuthState) {
    state.loginError = null;
    state.isLoading = true;
  },

  loginSuccess(state: AuthState, { token }) {
    Vue.cookies.set("token", token, {
      expires: "1d",
      domain: process.env.VUE_APP_DOMAIN,
    });
    localStorage.setItem("token", token);
    state.token = token;
    state.isLoading = false;
  },

  loginFailure(state: AuthState, { error }) {
    state.loginError = error;
    state.isLoading = false;
  },

  logout(state: AuthState) {
    Vue.cookies.remove("token");
    localStorage.removeItem("profile");
    state.token = null;
    state.profile = null;
  },
};

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
