
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import User from "../models/user";
import UsersTable from "../components/users/UsersTable.vue";

const user = namespace("user");

@Component({
  components: {
    UsersTable,
  },
})
export default class Users extends Vue {
  @user.Getter("getUsers")
  users!: User[];

  @user.Action("fetchAll")
  fetchAllUsers!: () => Promise<any>;

  mounted(): void {
    this.fetchAllUsers();
  }
}
