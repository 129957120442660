
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Radiologist from "../../models/radiologist";
const { DateTime } = require("luxon");
import NullableField from "../NullableField.vue";

@Component({
  components: {
    NullableField,
  },
})
export default class RadiologistsTable extends Vue {
  @Prop({ required: true }) readonly radiologists!: Radiologist[];
  @Prop({ default: false }) readonly singleSelect!: boolean;
  @Prop({ default: false }) readonly detailed!: boolean;
  @Prop({ default: false }) readonly isLoading!: boolean;

  simpleHeaders: Record<string, any>[] = [
    {
      text: "",
      value: "avatarUrl",
      sortable: false,
      width: "8%",
    },
    {
      text: "First Name",
      value: "firstName",
    },
    {
      text: "Middle Name",
      value: "middleName",
    },
    {
      text: "Last Name",
      value: "lastName",
    },
    {
      text: "Suffix",
      value: "suffix",
    },
    {
      text: "",
      value: "edit",
      sortable: false,
    },
  ];

  detailHeaders: Record<string, any>[] = [
    {
      text: "",
      value: "avatarUrl",
      sortable: false,
    },
    {
      text: "First Name",
      value: "firstName",
    },
    {
      text: "Middle Name",
      value: "middleName",
    },
    {
      text: "Last Name",
      value: "lastName",
    },
    {
      text: "Suffix",
      value: "suffix",
    },
    {
      text: "",
      value: "edit",
      sortable: false,
    },
  ];

  selected: string[] = [];

  options: any = {
    sortDesc: [false],
    page: 1,
    itemsPerPage: 20,
    sortBy: ["lastName"],
  };

  itemsPerPageOptions = [20, 50];
  totalItems = 0;

  get headers(): Record<string, any>[] {
    return this.detailed ? this.detailHeaders : this.simpleHeaders;
  }

  @Watch("selected")
  updateSelected(newVal: Radiologist[], _oldVal: Radiologist[]) {
    const radiologistIds = newVal.map((radiologist) => radiologist.id);
    this.$emit("update-selected", radiologistIds);
  }

  get footerProps(): any {
    return { "items-per-page-options": this.itemsPerPageOptions };
  }

  datetimeString(datetime: string): string {
    return DateTime.fromISO(datetime).toLocaleString(DateTime.DATETIME_MED);
  }

  offset(): number {
    const { page, itemsPerPage } = this.options;
    return (page - 1) * itemsPerPage;
  }

  sortParam(key: string): string {
    const keys: any = {
      createdAt: "inserted_at",
      id: "id",
      name: "name",
      companyName: "company_name",
      firstName: "first_name",
      lastName: "last_name",
      jobTitle: "job_title",
      stage: "stage",
      frMrr: "fr_mrr",
      frLicenseCount: "fr_license_count",
      email: "email",
      numberOfAgents: "num_agents",
    };

    return keys[key];
  }

  edit(radiologist: Radiologist) {
    this.$emit("edit", radiologist);
  }
}
