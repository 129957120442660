export default class MOTD {
  id: string | null;
  text: string | null;
  visible: boolean;
  url: string | null;

  constructor() {
    this.id = null;
    this.text = null;
    this.visible = false;
    this.url = null;
  }

  static fromJSON(json: any): MOTD {
    const motd = new MOTD();

    motd.id = json.id;
    motd.text = json.text;
    motd.visible = json.visible;
    motd.url = json.url;

    return motd;
  }

  toJSON(): any {
    return {
      text: this.text,
      visible: this.visible,
      url: this.url,
    };
  }
}
