import MOTDApi from "@/api/motd";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
import { State as RootState } from "../state";
import { MOTDState } from "./motdState";
import MOTD from "../../models/motd";
import Response from "../../models/response";

type MOTDContext = ActionContext<MOTDState, RootState>;

const state: MOTDState = {
  motds: [],
};

const getters: GetterTree<MOTDState, RootState> = {
  getMOTDs(state: MOTDState): MOTD[] {
    return state.motds;
  },
};

const actions: ActionTree<MOTDState, RootState> = {
  create(context: MOTDContext, { motd }): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("createRequest");

      MOTDApi.create(motd)
        .then((resp: Response) => {
          const data: MOTD = MOTD.fromJSON(resp.data);
          context.commit("createSuccess", { motd: data });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("createFailure");
          reject(resp);
        });
    });
  },

  delete(context: MOTDContext, motdId: string): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("deleteRequest");

      MOTDApi.delete(motdId)
        .then((resp: Response) => {
          context.commit("deleteSuccess", { motdId: motdId });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("deleteFailure");
          reject(resp);
        });
    });
  },

  fetchAll(context: MOTDContext): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("fetchAllRequest");

      MOTDApi.all({})
        .then((resp: Response) => {
          const data = resp.data.motds.map((d: any) => MOTD.fromJSON(d));
          context.commit("fetchAllSuccess", { motds: data });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("fetchAllFailure");
          reject(resp);
        });
    });
  },

  update(context: MOTDContext, { motdId, params }): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("updateRequest");

      MOTDApi.update(motdId, params)
        .then((resp: Response) => {
          const data: MOTD = MOTD.fromJSON(resp.data);
          context.commit("updateSuccess", { motd: data });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("updateFailure");
          reject(resp);
        });
    });
  },
};

const mutations: MutationTree<MOTDState> = {
  createRequest(_state: MOTDState) {
    // Nothing yet.
  },

  createSuccess(state: MOTDState, { motd }) {
    state.motds.push(motd);
  },

  createFailure(_state: MOTDState) {
    // Nothing yet.
  },

  fetchAllRequest(_state: MOTDState) {
    // Nothing yet.
  },

  fetchAllSuccess(state: MOTDState, { motds }) {
    state.motds = motds;
  },

  fetchAllFailure(_state: MOTDState) {
    // Nothing yet.
  },

  deleteRequest(_state: MOTDState) {
    // Nothing yet.
  },

  deleteSuccess(state: MOTDState, { motdId }) {
    state.motds = state.motds.filter((x) => x.id !== motdId);
  },

  deleteFailure(_state: MOTDState) {
    // Nothing yet.
  },

  updateRequest(_state: MOTDState) {
    // Nothing yet.
  },

  updateSuccess(state: MOTDState, { motd }) {
    const filtered = state.motds.filter((x) => x.id !== motd.id);
    filtered.push(motd);
    state.motds = filtered;
  },

  updateFailure(_state: MOTDState) {
    // Nothing yet.
  },
};

export const motd: Module<MOTDState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
