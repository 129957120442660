import axios from "axios";
import MOTD from "../models/motd";
import Response from "../models/response";
import Util from "./util";

export default {
  all(params: any): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/motds";

    return axios
      .get(url, { params: params, headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  create(motd: MOTD): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/motds";
    const params = motd.toJSON();

    return axios
      .post(url, params, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  delete(motdId: string): Promise<Response> {
    const url = `${process.env.VUE_APP_API_URL}/api/v1/motds/${motdId}`;

    return axios
      .delete(url, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  update(motdId: string, motd: MOTD): Promise<Response> {
    const url = `${process.env.VUE_APP_API_URL}/api/v1/motds/${motdId}`;
    const params = motd.toJSON();

    return axios
      .put(url, params, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },
};
