import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import "../scss/typography.scss";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md",
  },
  theme: {
    themes: {
      light: {
        background: "#ffffff",
        primary: "#061538",
        navy: "#061538",
        gold: "#ffb949",
      },
    },
  },
});
