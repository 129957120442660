
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import MOTDDialog from "../components/motds/MOTDDialog.vue";
import MOTDsTable from "../components/motds/MOTDsTable.vue";
import MOTD from "../models/motd";
import Response from "../models/response";

const motd = namespace("motd");

@Component({
  components: {
    MOTDsTable,
    MOTDDialog,
  },
})
export default class MOTDs extends Vue {
  createDialog = false;
  updateDialog = false;
  isUploadDialogOpen = false;
  selectedMOTDId: string | null = null;
  editingMOTD: MOTD | null = null;

  @motd.Getter("getMOTDs")
  motds!: MOTD[];

  @motd.Action("create")
  createMOTD!: (params: any) => Promise<any>;

  @motd.Action("update")
  updateMOTD!: (params: any) => Promise<any>;

  @motd.Action("delete")
  deleteMOTD!: (motdId: string) => Promise<any>;

  @motd.Action("fetchAll")
  fetchAllMOTDs!: () => Promise<any>;

  get hasSelectedMOTDs(): boolean {
    return this.selectedMOTDId !== null;
  }

  mounted() {
    this.fetchAllMOTDs();
  }

  close(): void {
    this.createDialog = false;
    this.updateDialog = false;
  }

  save(motd: MOTD): void {
    this.createMOTD({ motd: motd })
      .then((_resp: Response) => {
        this.close();

        const dialog: any = this.$refs.createMOTDDialog;
        dialog.reset();
      })
      .catch((resp: Response) => {
        const dialog: any = this.$refs.createMOTDDialog;
        dialog.errorMessage = resp.error.message;
      })
      .finally(() => {
        const dialog: any = this.$refs.createMOTDDialog;
        dialog.isLoading = false;
      });
  }

  update(params: any): void {
    this.updateMOTD({
      motdId: params.id,
      params: params,
    })
      .then((_resp: Response) => {
        this.close();

        const dialog: any = this.$refs.updateMOTDDialog;
        dialog.reset();
      })
      .catch((resp: Response) => {
        const dialog: any = this.$refs.updateMOTDDialog;
        dialog.errorMessage = resp.error.message;
      })
      .finally(() => {
        const dialog: any = this.$refs.updateMOTDDialog;
        dialog.isLoading = false;
      });
  }

  deleteSelectedMOTDs(): void {
    if (this.selectedMOTDId === null) {
      return;
    }
    this.deleteMOTD(this.selectedMOTDId);
    this.selectedMOTDId = null;
  }

  updateSelectedMOTDIds(motdIds: string[]): void {
    if (motdIds.length === 0) {
      this.selectedMOTDId = null;
    } else {
      this.selectedMOTDId = motdIds[0];
    }
  }

  showDialog(): void {
    this.createDialog = true;
  }

  showEdit(motd: MOTD): void {
    this.editingMOTD = motd;
    const dialog: any = this.$refs.updateMOTDDialog;
    dialog.setMOTD(motd);
    this.updateDialog = true;
  }
}
