import axios from "axios";
import Response from "../models/response";
import Util from "./util";

export default {
  all(params: any): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/users";

    return axios
      .get(url, { params: params, headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  sendVerificationEmail(): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/v1/users/resend-verification";

    return axios
      .post(url, {}, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  sendActivationEmail(): Promise<Response> {
    const url =
      process.env.VUE_APP_API_URL + "/v1/users/resend-authorization-request";

    return axios
      .post(url, {}, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },
};
