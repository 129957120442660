
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import RadiologistDialog from "../components/radiologists/RadiologistDialog.vue";
import RadiologistsTable from "../components/radiologists/RadiologistsTable.vue";
import Radiologist from "../models/radiologist";
import Response from "../models/response";

const radiologist = namespace("radiologist");

@Component({
  components: {
    RadiologistsTable,
    RadiologistDialog,
  },
})
export default class Radiologists extends Vue {
  createDialog = false;
  updateDialog = false;
  isUploadDialogOpen = false;
  selectedRadiologistId: string | null = null;
  editingRadiologist: Radiologist | null = null;

  @radiologist.Getter("isCreating")
  isCreating!: boolean;

  @radiologist.Getter("isLoading")
  isLoading!: boolean;

  @radiologist.Getter("isUpdating")
  isUpdating!: boolean;

  @radiologist.Getter("getRadiologists")
  radiologists!: Radiologist[];

  @radiologist.Action("create")
  createRadiologist!: (params: any) => Promise<any>;

  @radiologist.Action("update")
  updateRadiologist!: (params: any) => Promise<any>;

  @radiologist.Action("delete")
  deleteRadiologist!: (radiologistId: string) => Promise<any>;

  @radiologist.Action("fetchAll")
  fetchAllRadiologists!: () => Promise<any>;

  get hasSelectedRadiologists(): boolean {
    return this.selectedRadiologistId !== null;
  }

  mounted() {
    this.fetchAllRadiologists();
  }

  close(): void {
    this.createDialog = false;
    this.updateDialog = false;
  }

  get updateDialogTitle(): string {
    if (!this.editingRadiologist) {
      return "Update radiologist";
    }
    return `Update ${this.editingRadiologist.fullName()}`;
  }

  save(radiologist: Radiologist): void {
    this.createRadiologist({ radiologist: radiologist })
      .then((_resp: Response) => {
        this.close();

        const dialog: any = this.$refs.createRadiologistDialog;
        dialog.reset();
      })
      .catch((resp: Response) => {
        const dialog: any = this.$refs.createRadiologistDialog;
        dialog.errorMessage = resp.error.message;
      });
  }

  update(params: any): void {
    this.updateRadiologist({
      radiologistId: params.id,
      params: params,
    })
      .then((_resp: Response) => {
        this.close();

        const dialog: any = this.$refs.updateRadiologistDialog;
        dialog.reset();
      })
      .catch((resp: Response) => {
        const dialog: any = this.$refs.updateRadiologistDialog;
        dialog.errorMessage = resp.error.message;
      });
  }

  deleteSelectedRadiologists(): void {
    if (this.selectedRadiologistId === null) {
      return;
    }
    this.deleteRadiologist(this.selectedRadiologistId);
    this.selectedRadiologistId = null;
  }

  updateSelectedRadiologistIds(radiologistIds: string[]): void {
    if (radiologistIds.length === 0) {
      this.selectedRadiologistId = null;
    } else {
      this.selectedRadiologistId = radiologistIds[0];
    }
  }

  showDialog(): void {
    this.createDialog = true;
  }

  showEdit(radiologist: Radiologist): void {
    this.editingRadiologist = radiologist;
    const dialog: any = this.$refs.updateRadiologistDialog;
    dialog.setRadiologist(radiologist);
    this.updateDialog = true;
  }
}
