import * as Vuex from "vuex";
import Vue from "vue";

import { auth } from "./auth";
import { motd } from "./motd";
import { radiologist } from "./radiologist";
import { user } from "./user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    motd,
    radiologist,
    user,
  },
});
