
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class UploadFile extends Vue {
  @Prop({ default: "Upload a file" }) readonly title!: string;
  @Prop() readonly avatarUrl!: string | null;

  files: any[] = [];

  get showAvatar(): boolean {
    return !this.hasFiles && this.hasAvatar;
  }

  get showPlaceholder(): boolean {
    return !this.hasFiles && !this.hasAvatar;
  }

  get hasAvatar(): boolean {
    return (
      this.avatarUrl !== "/img/radiologist-placeholder.jpg" &&
      this.avatarUrl !== null
    );
  }

  get hasFiles(): boolean {
    return this.files.length > 0;
  }

  get previewURL(): string {
    if (!this.files[0]) {
      return "";
    }
    return URL.createObjectURL(this.files[0]);
  }

  close() {
    this.$emit("close");
  }

  addFile(e: any) {
    this.files = [];
    const droppedFiles = e.dataTransfer.files;
    if (!droppedFiles) return;
    [...droppedFiles].forEach((f) => {
      console.log(f);
      this.files.push(f);
      this.$emit("add-file", f);
    });
  }

  removeFile(file: any) {
    this.files = this.files.filter((f) => {
      return f != file;
    });
  }

  reset() {
    this.files = [];
  }

  upload() {
    this.$emit("upload", this.files[0]);
  }
}
