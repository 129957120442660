import UserApi from "@/api/user";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
import { State as RootState } from "../state";
import { UserState } from "./userState";
import User from "../../models/user";
import Response from "../../models/response";

type UserContext = ActionContext<UserState, RootState>;

const state: UserState = {
  users: [],
};

const getters: GetterTree<UserState, RootState> = {
  getUsers(state: UserState): User[] {
    return state.users;
  },
};

const actions: ActionTree<UserState, RootState> = {
  fetchAll(context: UserContext): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("fetchAllRequest");

      UserApi.all({})
        .then((resp: Response) => {
          const data = resp.data.users.map((d: any) => User.fromJSON(d));
          context.commit("fetchAllSuccess", { users: data });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("fetchAllFailure");
          reject(resp);
        });
    });
  },
};

const mutations: MutationTree<UserState> = {
  fetchAllRequest(_state: UserState) {
    // Nothing yet.
  },

  fetchAllSuccess(state: UserState, { users }) {
    state.users = users;
  },

  fetchAllFailure(_state: UserState) {
    // Nothing yet.
  },
};

export const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
