import Error from "./error";
import Pagination from "./pagination";

export default class Response {
  success: boolean;
  data: any | null;
  error: Error;
  pagination: Pagination;

  constructor() {
    this.success = false;
    this.data = null;
    this.error = new Error();
    this.pagination = new Pagination();
  }

  static fromJSON(json: any): Response {
    const resp = new Response();
    resp.success = json.success;

    if (json.data) {
      resp.data = json.data;
    }

    if (json.pagination) {
      resp.pagination = Pagination.fromJSON(json.pagination);
    }

    if (json.error) {
      resp.error = Error.fromJSON(json.error);
    }

    return resp;
  }

  static internalServerError(): Response {
    const resp = new Response();
    const error = new Error();
    error.code = "INTERNAL_SERVER_ERROR";
    error.message = "An internal server error occurred.";

    resp.success = false;
    resp.error = error;

    return resp;
  }

  static fromAxiosResponse(aResp: any): Response {
    if (aResp.response) {
      return Response.fromJSON(aResp.response.data);
    } else if (aResp.data) {
      return Response.fromJSON(aResp.data);
    }

    return Response.internalServerError();
  }
}
