import axios from "axios";
import store from "../store";
import router from "../router";
import Response from "../models/response";
import Util from "./util";

axios.interceptors.response.use(
  (response: any) => {
    return Promise.resolve(response);
  },
  (error: any) => {
    if (error.response.status === 401) {
      store.dispatch("auth/logout");
      router.replace({ name: "Login" });
    }

    return Promise.reject(error);
  }
);

export default {
  fetchProfile(): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/profile";

    return axios
      .get(url, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  login(email: string, pass: string): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/auth/login";
    const params = { email: email, password: pass };

    return axios
      .post(url, params)
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  requestPasswordReset(email: string | null): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/password/forgot";
    const params = { email: email };

    return axios
      .post(url, params)
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  resetPassword(
    token: string | null,
    password: string | null
  ): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/password/change";
    const params = { t: token, password: password };

    return axios
      .post(url, params)
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },
};
